import React, { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { PickerDropPane } from "filestack-react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormLabel from "@material-ui/core/FormLabel";
import AddAlert from "@material-ui/icons/AddAlert";
import Datetime from "react-datetime";
import FormControlLabel from "@material-ui/core/FormControlLabel";

//Form
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import Snackbar from "components/Snackbar/Snackbar.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridItem from "components/Grid/GridItem";
import Slide from "@material-ui/core/Slide";
import SweetAlert from "react-bootstrap-sweetalert";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";

import logoBsci from "assets/img/boston-scientific-logo.png";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function GenerarReporte(props) {
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";
  const ubicacion = props.match.params.ubicacion.replace(/_/g, " ");
  const [desde, setDesde] = useState(
    new Date().setDate(new Date().getDate() + 7)
  );
  const [files, setFiles] = useState([]);
  const [flag, setFlag] = useState("");
  const [tecnico, setTecnico] = useState("");
  const [categoria, setCategoria] = useState("");
  const [urlFile, setUrlFile] = useState([]);
  const [urlFileError, setUrlFileError] = useState();
  const [txtComentario, setTxtComentario] = React.useState("");
  const [txtZona, setTxtZona] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [tc, setTC] = React.useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const [flagUbicacion, setFlagUbicacion] = useState(false);
  const [txtEmail, setTxtEmail] = useState();

  const classes = useStyles();

  const successAlert = (codigo, tecnico) => {
    const tittleText = `Reporte ${codigo} realizado`;
    setAlert(
      <SweetAlert
        success
        // style={{ display: "block" }}
        style={{ display: "block", marginTop: "100px" }}
        title={tittleText}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        timeout={5000}
        // confirmBtnBsStyle="success"
        showCloseButton
        confirmBtnBsStyle={"secondary"}
        // confirmBtnStyle={classes.button + " " + classes.success}
      >
        {/* Se ha asignado un nuevo reporte a {tecnico} */}
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const showNotification = () => {
    setTC(true);
    setTimeout(function() {
      setTC(false);
    }, 6000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let errorMessage = {};
    !txtComentario
      ? (errorMessage.comentario = "Debe escribir el comentario del tiquete")
      : setFlag(200);
    !categoria
      ? (errorMessage.categoria = "Debe selecionar una categoría")
      : setFlag(200);

    setErrors(errorMessage);

    if (Object.keys(errorMessage).length === 0) {
      setLoadingModal(true);
      let urlFiles = [];
      if (files.length > 0) {
        files.forEach((el) => urlFiles.push(el.url));
      }
      const reporteToSave = {
        comentario: txtComentario,
        lugar: ubicacion,
        email: txtEmail,
        categoria: categoria,
        url: urlFiles,
        otro_lugar: txtZona,
      };
      // console.log(reporteToSave);
      await axios
        // .post("http://localhost:5005/api/directorio/averia", reporteToSave)
        .post("https://epstool.com/api/directorio/averia", reporteToSave)
        .then((res) => {
          // console.log(res);
          setErrors({});
          setTxtComentario("");
          setTxtZona("");
          setCategoria("");
          setTxtEmail("");
          setFiles([]);
          setLoadingModal(false);
          successAlert(res.data.codigoReporte);
          // showNotification();
        })
        // .then(() => {
        //   axios
        //     .get(`http://localhost:5005/api/directorio/lastreport/code`)
        //     .then((res) => {
        //       console.log(res);
        //       setLoadingModal(false);
        //       successAlert(res.data.codigoReporte);
        //     });
        // })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  const handleListFiles = async (uploadedFiles) => {
    uploadedFiles.forEach((element) => {
      // console.log(element);

      setFiles((prevFiles) => [...prevFiles, element]);
    });
  };

  const handleDeleteFile = async (idFileToDelete) => {
    setFiles(files.filter((item) => item.uploadId !== idFileToDelete));
  };

  return (
    <div>
      {alert}
      <GridContainer>
        <GridItem xs={12} md={10}>
          <Card>
            <CardHeader
              className={`${classes.cardHeader} ${classes.center}`}
              color="bsci"
            >
              <GridContainer>
                <GridItem xs={12} md={8} lg={6}>
                  <img src={logoBsci} alt="Logo" width="50%" />
                </GridItem>
                <GridItem xs={12} md={4} lg={6}>
                  <h3>Generar reporte</h3>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Reporte:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <CustomInput
                    multiline
                    labelText="Comentario"
                    id="txtComentario"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      onChange: (event) => {
                        setTxtComentario(event.target.value);
                      },
                      type: "text",
                      value: txtComentario,
                    }}
                  />
                  <Danger>
                    {errors.comentario && (
                      <div className="invalid-feedback">
                        {errors.comentario}
                      </div>
                    )}
                  </Danger>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Categoría:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="multiple-select"
                      className={classes.selectLabel}
                    >
                      Selecione categoría del reporte
                    </InputLabel>
                    <Select
                      value={categoria}
                      // onChange={(e) => handleMaquinas(e.target.value)}
                      onChange={(e) => setCategoria(e.target.value)}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Selecione
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"A"}
                      >
                        Mantenimiento de edificio
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"B"}
                      >
                        Limpieza
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"C"}
                      >
                        Técnico de facilidades
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"D"}
                      >
                        Cafetería
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={"Categoria"}
                      >
                        Categoria
                      </MenuItem>
                    </Select>
                    <Danger>
                      {errors.categoria && (
                        <div className="invalid-feedback">
                          {errors.categoria}
                        </div>
                      )}
                    </Danger>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    La ubicación del reporte es{" "}
                    {ubicacion ? ubicacion : "No detallado"} desea cambiar o
                    detallar el lugar del reporte?
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={flagUbicacion === true}
                          onChange={() => setFlagUbicacion(true)}
                          value={true}
                          name="radio button demo"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={"Si"}
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          checked={flagUbicacion === false}
                          onChange={() => setFlagUbicacion(false)}
                          value={false}
                          name="radio button demo"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={"No"}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              {flagUbicacion === false ? (
                " "
              ) : (
                <GridContainer>
                  <GridItem xs={12} sm={4} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Detalle del lugar
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={9}>
                    <CustomInput
                      multiline
                      labelText="Zona"
                      id="txtZona"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        onChange: (event) => {
                          setTxtZona(event.target.value);
                        },
                        type: "text",
                        value: txtZona,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              )}
              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Ingrese su email para recibir notificaciones del reporte
                    (opcional):
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <CustomInput
                    multiline
                    labelText="Email"
                    id="txtEmail"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => {
                        setTxtEmail(event.target.value);
                      },
                      type: "email",
                      value: txtEmail,
                    }}
                  />
                  {/* <Danger>
                    {errors.comentario && (
                      <div className="invalid-feedback">
                        {errors.comentario}
                      </div>
                    )}
                  </Danger> */}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Archivos:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <PickerDropPane
                    apikey={API_KEY}
                    onUploadDone={(res) => handleListFiles(res.filesUploaded)}
                    pickerOptions={{ maxFiles: 5 }}
                    onError={(error) =>
                      setUrlFileError(
                        "Se ha producido un error, por favor intentarlo nuevamente"
                      )
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Archivos Cargados:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8} md={9}>
                  <List>
                    {files.length === 0 ? (
                      <p className={classes.labelHorizontalIzq}>
                        No hay archivos cargados
                      </p>
                    ) : (
                      files.map((file) => (
                        <ListItem dense key={file.uploadId}>
                          <ListItemText primary={file.filename} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="open"
                              onClick={() => window.open(file.url, "_blank")}
                            >
                              <ImageSearchIcon />
                            </IconButton>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDeleteFile(file.uploadId)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                    )}
                  </List>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={0} md={3}></GridItem>
                <GridItem xs={12} md={9}>
                  <Button fullWidth color="success" onClick={handleSubmit}>
                    Crear reporte
                  </Button>
                </GridItem>
              </GridContainer>
              <br />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={loadingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLoadingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Aplicación tiquetes de trabajo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridItem xs={12}>Generando el tiquete solicitado...</GridItem>
          <GridContainer>
            <br />
            <br />
            <br />
            <GridItem xs={3}></GridItem>
            <GridItem xs={6}>
              <CircularProgress />
            </GridItem>
            <GridItem xs={3}></GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <Snackbar
        place="tc"
        color="success"
        icon={AddAlert}
        message="Reporte creado con éxito"
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
